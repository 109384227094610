import { render, staticRenderFns } from "./SystemeInformationSanitaires.vue?vue&type=template&id=15b81a70&scoped=true"
import script from "./SystemeInformationSanitaires.vue?vue&type=script&lang=js"
export * from "./SystemeInformationSanitaires.vue?vue&type=script&lang=js"
import style0 from "./SystemeInformationSanitaires.vue?vue&type=style&index=0&id=15b81a70&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15b81a70",
  null
  
)

export default component.exports